<template>
    <cms-block v-bind="$props" @block-selected="selectBlock">
        <b-carousel @sliding-end="slideEnd" :controls="block.controls" :indicators="block.mediaProxy.indicators" :interval="0" :fade="block.transition === 'fade'" :no-animation="block.transition === 'none'" :value="slideIndex">
            <b-carousel-slide v-for="child in block.children" :key="child.id">
                <template #img>
                    <cms-block-selector @block-selected="selectBlock" :block="child" is-child :block-selection="blockSelection" :editable="editable" :align="block.mediaProxy.align"/>
                </template>
            </b-carousel-slide>
        </b-carousel>
    </cms-block>
</template>

<script>
import CmsBlock from "@/components/editor/rendering/blocks/cms-block.vue";

// cms-block-slideshow #hot-reload-debug
export default {
    name: `cms-block-slideshow`,
    components: {
        CmsBlockSelector: () => import(`@/components/editor/rendering/blocks/cms-block-selector.vue`),
        CmsBlock
    },
    extends: CmsBlock,
    computed: {
        slideIndex() {
            if (this.blockSelection) {
                return this.block.children.indexOf(this.blockSelection);
            } else {
                return 0;
            }
        },
        interval() {
            if (this.selected) {
                return 0;
            } else {
                return this.block.interval;
            }
        }
    },
    methods: {
        slideEnd(index) {
            if (this.selected && this.blockSelection) {
                this.onSelected(this.block.children[index]);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
